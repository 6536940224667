import * as React from 'react';

import Router from 'next/router';
// eslint-disable-next-line import/order
import { NextSeo } from 'next-seo';

import { Loader } from '@/components/design-system';
import Main from '@/components/shared/Main';
import { useAuth0 } from '@/context/auth';

const LoginPage: React.FC = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (!loading && !isAuthenticated) {
      loginWithRedirect({
        appState: { targetUrl: '/' },
      });
    }
    if (!loading && isAuthenticated) {
      Router.replace('/account/subscription');
    }
  }, [loading, isAuthenticated, loginWithRedirect]);

  return (
    <Main>
      <NextSeo title='Login' />
      <Loader format='overlay' mode='dark' />
    </Main>
  );
};

export default LoginPage;
